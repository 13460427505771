<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'User.Delegation.Header' | translate }}</h1>
  </div>
</div>
<delegation-overview
  *ngIf="anyDelegations; else noDelegations"
  [delegations]="delegations"
  [institutionNameResolver]="getInstitutionName.bind(this)" />
<ng-template #noDelegations>
  {{ 'User.Delegation.NoDelegations' | translate }}
</ng-template>
