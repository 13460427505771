import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { FormControl, FormGroup } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { appConfig } from 'config/appConfig';

@Component({
  selector: 'app-account-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public headerImageUrl1 = appConfig.headerImageUrl1;
  public headerImageUrl2 = appConfig.headerImageUrl2;

  languageForm = new FormGroup({
    selectedLanguage: new FormControl(this.accountService.getUserLanguage()),
  });

  isTermsAccepted: boolean = false;
  selectedLanguage: string;

  constructor(
    public translate: TranslateService,
    public accountService: AccountService,
    public applicationService: ApplicationService,
    private oauthService: OAuthService,
  ) {
    this.selectedLanguage = accountService.getUserLanguage();
  }

  ngOnInit(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.accountService.updateUserRoles();
      this.accountService.loadUserLanguage();
    } else {
      this.oauthService.events.subscribe((event) => {
        if (event.type === 'token_received' && this.oauthService.hasValidAccessToken()) {
          this.accountService.setUsernameFromToken();
          this.accountService.updateUserRoles();
          this.accountService.loadUserLanguage();
        }
      });
    }

    this.languageForm.controls.selectedLanguage?.valueChanges.subscribe((lang) => {
      if (lang) {
        this.changeLanguage(lang);
      }
    });
  }

  changeLanguage(lang: string) {
    this.accountService.setUserLanguage(lang);
    this.selectedLanguage = lang;
  }

  loginEmail() {
    this.oauthService.initLoginFlow(undefined, { ui_locales: this.accountService.getUserLanguage().split('-')[0] });
  }

  loginHIN() {
    this.accountService.loginWithIdp('hin');
  }
}
