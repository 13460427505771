import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { NgAceSubmenu } from '@app/_ace/directives/ace-submenu.directive';
import { AcceptTermsDialogComponent } from '@app/_dialogs/accept-terms-dialog/accept-terms-dialog.component';
import { IframeDialogComponent } from '@app/_dialogs/iframe-dialog/iframe-dialog.component';
import { PdfViewerDialogComponent } from '@app/_dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { AccountService } from '@app/_services/account.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InvoiceService } from '@app/_services/invoice.service';

@Component({
  selector: 'app-sidebar-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SidebarSubmenuComponent {
  @Input() items!: any;

  constructor(
    public parentSubmenu: NgAceSubmenu,
    public accountService: AccountService,
    public invoiceService: InvoiceService,
    public errorHandler: ErrorHandlerService,
    public translate: TranslateService,
    private modalService: BsModalService,
  ) {}

  toggleSubmenu(navItem: any, navItemSub?: NgAceSubmenu) {
    // Only toggle when sidemenu is active, not in horizontal menu.
    if (window.outerWidth < 1200) {
      navItem.isToggling = true; // adds '.is-toggling' class which is used in CSS themes

      // `parentSubmenu` is usually the main `.nav` in `.sidebar` (the parent `NgAceSubmenu`)
      this.parentSubmenu.toggle(navItem, navItemSub);
    }
  }

  getRouterLink(navItem: any): string[] | undefined {
    if (navItem.link) {
      return ['/' + navItem.link];
    } else {
      return undefined;
    }
  }

  collapseSubmenu(navItemOpen: boolean) {
    // Only collapse submenus when sidemenu is active, not in horizontal menu.
    if (window.outerWidth >= 1200) {
      return false;
    } else {
      return !navItemOpen;
    }
  }

  onLinkClick(navItem: any) {
    if (navItem.action) {
      if (navItem.action == 'acceptInvoiceTerms') {
        this.showAcceptInvoiceTermsDialog();
      } else if (navItem.action == 'openInvoiceManual') {
        this.openInvoiceManual();
      } else if (navItem.action == 'openInvoiceTerms') {
        this.openInvoiceTerms();
      }
    }
  }

  showAcceptInvoiceTermsDialog(): void {
    AcceptTermsDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      'html/Nutzungsbedingungen_Modul_Invoice.html',
      'Invoice.AcceptTermsDialog.Title',
      'Invoice.AcceptTermsDialog.Text',
      'Global.Ok',
      'Global.Cancel',
      'modal-1000',
    ).then((modalRef) => {
      modalRef.content?.onClose.subscribe((onCloseResult) => {
        if (onCloseResult.accepted) {
          this.acceptInvoiceTerms();
        }
      });
    });
  }

  acceptInvoiceTerms(): void {
    this.invoiceService.acceptTerms().subscribe(
      (result) => {
        this.accountService.updateUserRoles();
      },
      (errorResponse: HttpErrorResponse) => {
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  openInvoiceManual(): void {
    this.translate.get('Nav.Links.InvoiceManual').subscribe((res) => {
      var title = `${res}`;
      PdfViewerDialogComponent.showDialog(this.modalService, title, '/pdf/Anleitung_Invoice.pdf');
    });
  }

  openInvoiceTerms(): void {
    IframeDialogComponent.showDialogTranslated(
      this.translate,
      this.modalService,
      '/html/Nutzungsbedingungen_Modul_Invoice.html',
      'Nav.Links.InvoiceTerms',
      undefined,
      'Global.Close',
      undefined,
      'modal-1000',
    );
  }

  // openInvoice() {
  //   this.accountService.currentUser$.subscribe(currentUserResult => {
  //     if (currentUserResult) {
  //       var form = document.createElement("form");
  //       form.target = "view";
  //       form.method = "POST";
  //       form.action = "https://invoice.meimed.ch/Account/NewLogin";
  //       var params: any = {
  //         "token": "dd406fe2-91e3-40bd-a0ba-e2a4a7d4da8a",
  //         "uid": currentUserResult.userName
  //       };

  //       for (var i in params) {
  //           if (params.hasOwnProperty(i)) {
  //             var input = document.createElement('input');
  //             input.type = 'hidden';
  //             input.name = i;
  //             input.value = params[i];
  //             form.appendChild(input);
  //           }
  //       }

  //       document.body.appendChild(form);
  //       form.submit();
  //       window.open('', 'view');
  //     }
  //   });
  // }
}
