import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_providers/authguard';

import { ForceLoginWithIdpComponent } from '@app/_views/account/force-login-with-idp/force-login-with-idp.component';
import { AccountHinLinkedComponent } from '@app/_views/account/hin-linked/account-hin-linked.component';
import { UserDelegationComponent } from '@app/_views/user/user-delegation/user-delegation.component';
import { UserInvoiceCreateComponent } from '@app/_views/user/user-invoice-create/user-invoice-create.component';
import { LoginComponent } from './_views/account/login/login.component';
import { AccountRegisterComponent } from './_views/account/register/account-register.component';
import { AdminInfoComponent } from './_views/admin/admin-info/admin-info.component';
import { ContractsComponent } from './_views/admin/contracts/contracts.component';
import { EmailsComponent } from './_views/admin/emails/emails.component';
import { EventLogsComponent } from './_views/admin/event-logs/event-logs.component';
import { InstitutionsComponent } from './_views/admin/institutions/institutions.component';
import { InsurancesComponent } from './_views/admin/insurances/insurances.component';
import { RulesComponent } from './_views/admin/rules/rules.component';
import { CatalogManagementComponent } from './_views/catalog/catalog-management/catalog-management.component';
import { EmployeeManagementComponent } from './_views/employee/employee-management/employee-management.component';
import { DashboardComponent } from './_views/home/dashboard/dashboard.component';
import { InfoEditComponent } from './_views/home/info-edit/info-edit.component';
import { InfoComponent } from './_views/home/info/info.component';
import { InsurancePendingUsercontractsComponent } from './_views/insurance/insurance-pending-usercontracts/insurance-pending-usercontracts.component';
import { InsuranceUsercontractsComponent } from './_views/insurance/insurance-usercontracts/insurance-usercontracts.component';
import { InvoiceControllingComponent } from './_views/invoice/invoice-controlling/invoice-controlling.component';
import { InvoiceServiceProvidersComponent } from './_views/invoice/invoice-serviceproviders/invoice-serviceproviders.component';
import { UserContractsComponent } from './_views/user/user-contracts/user-contracts.component';
import { UserGroupsComponent } from './_views/user/user-groups/user-groups.component';
import { UserInvoiceVisitsComponent } from './_views/user/user-invoice-visits/user-invoice-visits.component';
import { UserInvoiceComponent } from './_views/user/user-invoice/user-invoice.component';
import { UserInvoicesComponent } from './_views/user/user-invoices/user-invoices.component';
import { UserManagementComponent } from './_views/user/user-management/user-management.component';
import { UserPatientsComponent } from './_views/user/user-patients/user-patients.component';
import { UserProfileComponent } from './_views/user/user-profile/user-profile.component';

const routes: Routes = [
  { path: 'account/login', component: LoginComponent },
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  {
    path: 'account/register',
    component: AccountRegisterComponent,
    canActivate: [AuthGuard],
    data: { role: 'UserRegistrationPending' },
  },
  { path: 'account/hin-linked', component: AccountHinLinkedComponent },
  { path: 'account/force-login-with-idp', component: ForceLoginWithIdpComponent },
  {
    path: 'user/management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { role: 'UserManagement' },
  },
  {
    path: 'user/management/pending',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { role: 'UserManagement' },
  },
  { path: 'user/groups', component: UserGroupsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  {
    path: 'user/contracts',
    component: UserContractsComponent,
    canActivate: [AuthGuard],
    data: { role: 'CloseContractsUser' },
  },
  { path: 'user/invoices', component: UserInvoicesComponent, canActivate: [AuthGuard], data: { role: 'Invoice' } },
  {
    path: 'user/invoices/:patientid',
    component: UserInvoicesComponent,
    canActivate: [AuthGuard],
    data: { role: 'Invoice' },
  },
  { path: 'user/invoice/:id', component: UserInvoiceComponent, canActivate: [AuthGuard], data: { role: 'Invoice' } },
  {
    path: 'user/invoice-create',
    component: UserInvoiceCreateComponent,
    canActivate: [AuthGuard],
    data: { role: 'Invoice' },
  },
  {
    path: 'user/invoice/visits/:id',
    component: UserInvoiceVisitsComponent,
    canActivate: [AuthGuard],
    data: { role: 'Invoice' },
  },
  {
    path: 'invoice/controlling',
    component: InvoiceControllingComponent,
    canActivate: [AuthGuard],
    data: { role: 'InvoiceControlling' },
  },
  {
    path: 'invoice/serviceproviders',
    component: InvoiceServiceProvidersComponent,
    canActivate: [AuthGuard],
    data: { role: 'InvoiceControlling' },
  },
  { path: 'user/:userId/contracts', component: UserContractsComponent, canActivate: [AuthGuard] },
  { path: 'employee/management/:groupId', component: EmployeeManagementComponent, canActivate: [AuthGuard] },
  { path: 'home', redirectTo: 'home/dashboard', pathMatch: 'full' },
  {
    path: 'home/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { forbiddenRole: 'UserRegistrationPending' },
  },
  { path: 'home/info', component: InfoComponent, canActivate: [AuthGuard] },
  { path: 'home/info/edit', component: InfoEditComponent, canActivate: [AuthGuard], data: { role: 'EditInfoLinks' } },
  { path: 'user/patients', component: UserPatientsComponent, canActivate: [AuthGuard], data: { role: 'Invoice' } },
  { path: 'user/delegation', component: UserDelegationComponent, canActivate: [AuthGuard], data: { role: 'Delegate' } },
  { path: 'admin/eventlogs', component: EventLogsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/info', component: AdminInfoComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/emails', component: EmailsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/rules', component: RulesComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/insurances', component: InsurancesComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/institutions', component: InstitutionsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'admin/contracts', component: ContractsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  {
    path: 'insurance/contracts',
    component: InsuranceUsercontractsComponent,
    canActivate: [AuthGuard],
    data: { role: 'ViewContracts' },
  },
  {
    path: 'insurance/pendingcontracts',
    component: InsurancePendingUsercontractsComponent,
    canActivate: [AuthGuard],
    data: { role: 'ViewContracts' },
  },
  { path: 'catalog/management/:catalogViewId', component: CatalogManagementComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
