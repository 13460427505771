import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { DelegationInformation } from '@app/_models/delegationDto';
import { InstitutionDto } from '@app/_models/institutionDto';
import { RegisterDto } from '@app/_models/registerDto';
import { AccountService } from '@app/_services/account.service';
import { ApplicationService } from '@app/_services/application.service';
import { ErrorHandlerService } from '@app/_services/errorHandler.service';
import { InstitutionService } from '@app/_services/institution.service';
import { UserService } from '@app/_services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';

enum RoleEnum {
  doctor,
  hoteladministration,
  insurance,
  others,
  notSelected,
}

@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.css'],
})
export class AccountRegisterComponent implements OnInit {
  isExplanationExpanded = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  model: RegisterDto = {};
  institutions!: InstitutionDto[];
  userRegisterForm!: UntypedFormGroup;
  submitted: boolean = false;
  public appComponent = AppComponent;
  protected readonly RoleEnum = RoleEnum;
  private selectedRoleSubject = new BehaviorSubject<RoleEnum>(RoleEnum.notSelected);
  selectedRole$ = this.selectedRoleSubject.asObservable();
  delegations: DelegationInformation[] = [];
  anyDelegations = false;

  constructor(
    public translate: TranslateService,
    private accountService: AccountService,
    private userService: UserService,
    private institutionService: InstitutionService,
    public applicationService: ApplicationService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private oauthService: OAuthService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.userRegisterForm = this.fb.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        institutionName: [''],
        email: ['', [Validators.required, Validators.email]],
        language: [this.translate.currentLang, [Validators.required]],
        termsAccepted: [
          false,
          {
            validators: [Validators.requiredTrue],
            updateOn: 'change',
          },
        ],
        title: [''],
        function: [''],
        street: [''],
        postalCode: [''],
        city: [''],
        mobilePhoneNumber: [''],
        glnNumber: [''],
        zsrNumber: [''],
        selectedInstitutions: [[]],
      },
      {
        updateOn: 'blur',
      },
    );

    this.applicationService.getApplicationConfig().subscribe((config) => {
      if (config.features.simplifiedRegistration) {
        this.userRegisterForm.get('function')?.setValidators([Validators.required]);
        this.userRegisterForm.get('function')?.updateValueAndValidity();
      }
    });

    this.loadingSubject.next(true);

    this.userService.getDelegations().subscribe((delegations) => {
      this.delegations = delegations;
      this.anyDelegations = delegations.some((delegation) => delegation.delegators.length > 0);
      if (this.anyDelegations) {
        this.selectedRole = RoleEnum.others;
      }
      this.loadingSubject.next(false);
    });

    this.selectedRole$.subscribe((role) => {
      const doctorFields = [
        'title',
        'function',
        'street',
        'postalCode',
        'city',
        'mobilePhoneNumber',
        'glnNumber',
        'zlnNumber',
      ];

      doctorFields.forEach((field) => {
        const control = this.userRegisterForm.get(field);
        if (control) {
          if (role === RoleEnum.doctor) {
            // Set specific validators for each field
            if (field === 'mobilePhoneNumber') {
              control.setValidators([Validators.required, Validators.pattern('^(\\+41[0-9]{9})$')]);
            } else if (field === 'glnNumber') {
              control.setValidators([Validators.required, Validators.pattern('^\\d{13}$')]);
            } else if (field == 'zlnNumber') {
              control.setValidators([Validators.pattern('(^[a-zA-Z]\\d{6}$)|(^\\d{6}[a-zA-Z]$)')]);
            } else {
              control.setValidators([Validators.required]);
            }
          } else {
            control.clearValidators();
          }
          control.updateValueAndValidity();
        }
      });
    });

    this.accountService.hasRole('UserRegistrationPending').subscribe((isRegistrationPending) => {
      if (isRegistrationPending) {
        this.institutionService.getChildInstitutions().subscribe(
          (result) => {
            this.institutions = result;
          },
          (errorResponse: HttpErrorResponse) => {
            this.errorHandler.displayErrorDialog(errorResponse);
          },
        );

        // prefill form from JWT token
        if (this.oauthService.hasValidAccessToken()) {
          const jwtHelper = new JwtHelperService();
          const decodedToken: any = jwtHelper.decodeToken(this.oauthService.getAccessToken());

          this.userRegisterForm.controls.firstName.setValue(decodedToken.given_name);
          this.userRegisterForm.controls.lastName.setValue(decodedToken.family_name);
          this.userRegisterForm.controls.email.setValue(decodedToken.email);
        }

        this.userRegisterForm.valueChanges.subscribe((changes) => {
          for (let key of Object.keys(changes)) {
            this.model[key] = changes[key];

            if (key == 'language') {
              this.accountService.setUserLanguage(changes[key]);
            }
          }
        });
      } else {
        this.router.navigate(['/home/dashboard']);
      }
    });
  }

  getInstitutionName(institutionId: number) {
    return this.institutions.find((institution) => institution.id === institutionId)?.title ?? institutionId.toString();
  }

  get selectedRole(): RoleEnum {
    return this.selectedRoleSubject.value;
  }

  set selectedRole(value: RoleEnum) {
    this.selectedRoleSubject.next(value);
  }
  get f() {
    return this.userRegisterForm.controls;
  }

  register(): void {
    this.loadingSubject.next(true);

    const filteredRegistrationData = this.getFilteredModel();

    this.accountService.register(filteredRegistrationData).subscribe(
      (result) => {
        this.loadingSubject.next(false);
        if (!result.value.lockoutEnabled) {
          this.router.navigate(['/home/dashboard']);
        }
        this.submitted = true;
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSubject.next(false);
        this.errorHandler.displayErrorDialog(errorResponse);
      },
    );
  }

  getFilteredModel(): any {
    const modelCopy = { ...this.model };

    if (this.selectedRole !== RoleEnum.doctor) {
      const fieldsToDelete = [
        'title',
        'street',
        'postalCode',
        'city',
        'mobilePhoneNumber',
        'selectedInstitutions',
        'glnNumber',
        'zsrNumber',
      ];

      if (!AppComponent.features.simplifiedRegistration) {
        fieldsToDelete.push('function', 'institutionName');
      }

      fieldsToDelete.forEach((field) => delete modelCopy[field]);
    }

    return modelCopy;
  }

  mobilePhoneNumberChange(event: any): void {
    let inputValue: any = event.target.value;
    var matchLocalNumber = inputValue.match('^0([0-9]{9})$');

    if (matchLocalNumber) {
      this.userRegisterForm.controls['mobilePhoneNumber'].setValue('+41' + matchLocalNumber[1]);
    }
  }

  hasRequiredValidator(control: AbstractControl): Boolean {
    return control.hasValidator(Validators.required);
  }

  logout() {
    this.accountService.logout();
  }

  protected readonly formatDateOnly = formatDateOnly;
}
