<div *ngFor="let delegationInformation of delegations">
  {{ 'User.Delegation.DelegationInformation' | translate }}
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th tooltip="{{ 'User.Delegation.YourAccount' | translate }}{{ delegationInformation.delegateExternalUserId }}">
          {{ delegationInformation.delegationSource }}
        </th>
        <th>{{ 'User.Delegation.ContractoCoreAccount' | translate }}</th>
        <th>{{ 'Global.Institutions' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let delegation of delegationInformation.delegators">
        <td tooltip="{{ delegation.delegatorExternalUserId }} {{ delegation.delegatorExternalUserGln }}">
          {{ delegation.delegatorExternalUserDisplay ?? delegation.delegatorExternalUserId }}
        </td>
        <td>
          {{ delegation.delegatorUserDisplay || ('User.Delegation.DelegatorNeedsToRegister' | translate) }}
        </td>
        <td>
          <ul>
            <li *ngFor="let institutionDelegation of delegation.institutionDelegations">
              {{ institutionNameResolver(institutionDelegation.institutionId) }}
              <span *ngIf="institutionDelegation.delegatedFrom">
                {{ formatDateOnly(institutionDelegation.delegatedFrom) }}</span
              >
              <span *ngIf="institutionDelegation.delegatedUntil">
                - {{ formatDateOnly(institutionDelegation.delegatedUntil) }}</span
              >
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</div>
