import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { formatDateOnly } from '@app/_helpers/functions/date-functions';
import { DelegationInformation } from '@app/_models/delegationDto';

@Component({
  selector: 'delegation-overview',
  templateUrl: './delegation-overview.component.html',
})
export class DelegationOverviewComponent implements OnInit {
  @Input()
  delegations: DelegationInformation[] = [];
  @Input()
  institutionNameResolver: (id: number) => string = (id) => id.toString();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {}

  protected readonly formatDateOnly = formatDateOnly;
}
