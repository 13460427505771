<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="container container-plus">
  <div class="row">
    <div class="col d-flex justify-content-end">
      <div [formGroup]="languageForm">
        <select formControlName="selectedLanguage">
          <option value="de-CH">Deutsch</option>
          <option value="fr-CH">Français</option>
          <option value="it-CH">Italiano</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="alert alert-info title text-center">
        <img *ngIf="headerImageUrl1" height="80" [src]="headerImageUrl1" [alt]="'Nav.Title' | translate" />
        <img *ngIf="headerImageUrl2" height="80" [src]="headerImageUrl2" [alt]="'Nav.Title' | translate" />
        <h1 *ngIf="!headerImageUrl1 && !headerImageUrl2">{{ 'Home.Title' | translate }}</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="alert alert-info" [innerHTML]="'Home.Info' | translate" style="font-size: 18px"></div>
    </div>
  </div>
  <div class="row" *ngIf="(applicationService.currentApplicationStatus$ | async)?.displayWarningMessage">
    <div class="col">
      <div class="alert alert-danger">
        {{ (applicationService.currentApplicationStatus$ | async)?.warningMessage }}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="applicationService.lockoutText() | async">
    <div class="col">
      <div class="alert alert-danger">
        {{ applicationService.lockoutText() | async }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <!-- show this in desktop -->
      <div class="d-none d-lg-block col-md-8 mt-lg-4 px-0">
        <h4 class="text-dark-tp4 border-b-1 brc-secondary-l2 pb-1 text-130">
          {{ 'Account.Login.LoginTitle' | translate }}
        </h4>
      </div>

      <!-- show this in mobile device -->
      <div class="d-lg-none text-secondary-m1 my-4 text-center">
        {{ 'Account.Login.LoginTitle' | translate }}
      </div>

      <div class="form-group col-sm-10">
        <label class="d-inline-block mt-0 mb-0 text-dark-l1">
          <input type="checkbox" class="mr-1 mt-2" [(ngModel)]="isTermsAccepted" />
          <span [innerHTML]="'Account.AcceptTerms' | translate"></span>
        </label>
        <button
          class="btn btn-hin btn-block px-4 btn-bold mt-3 mb-2"
          (click)="loginHIN()"
          [disabled]="!isTermsAccepted">
          {{ 'Home.Login.HIN' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block px-4 btn-bold mt-3 mb-2"
          (click)="loginEmail()"
          [disabled]="!isTermsAccepted">
          {{ 'Home.Login.EmailPassword' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
