import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DelegationInformation } from '@app/_models/delegationDto';
import { InstitutionService } from '@app/_services/institution.service';
import { UserService } from '@app/_services/user.service';
import { BehaviorSubject, zip } from 'rxjs';

@Component({
  selector: 'app-user-delegation',
  templateUrl: './user-delegation.component.html',
  styleUrls: ['./user-delegation.component.css'],
})
export class UserDelegationComponent implements OnInit, AfterViewInit {
  anyDelegations = false;
  delegations: DelegationInformation[] = [];
  private institutionMap: Map<number, string> = new Map();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private userService: UserService,
    private institutionService: InstitutionService,
  ) {}

  ngOnInit(): void {
    this.loadingSubject.next(true);

    zip(this.institutionService.getChildInstitutions(), this.userService.getDelegations()).subscribe(
      ([institutions, delegations]) => {
        this.institutionMap = new Map<number, string>(
          institutions.map((institution) => [institution.id, institution.title]),
        );
        this.delegations = delegations;
        this.anyDelegations = delegations.filter((delegation) => delegation.delegators.length > 0).length > 0;
        this.loadingSubject.next(false);
      },
    );
  }

  getInstitutionName(institutionId: number) {
    return this.institutionMap.get(institutionId) ?? institutionId.toString();
  }

  ngAfterViewInit(): void {}
}
