<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>
      {{ 'Global.Invoice' | translate }}
      <ng-container *ngIf="invoice">{{ invoice.id }}</ng-container>
    </h1>
  </div>
  <div class="header-toolbar">
    <button
      (click)="showSendInvoiceDialog()"
      *ngIf="invoice?.status == invoiceStatus.Draft"
      class="btn btn-xs btn-success">
      {{ 'User.Invoice.SendInvoice' | translate }}
    </button>
    <button
      (click)="editInvoice()"
      *ngIf="invoice?.status == invoiceStatus.Draft"
      class="btn btn-sm"
      [tooltip]="'Global.Edit' | translate"
      container="body"
      placement="left">
      <div class="green fa fa-pencil-alt fa-xl"></div>
    </button>
    <button
      (click)="openInvoicePdf()"
      class="btn btn-sm"
      [tooltip]="'User.Invoice.ShowInvoicePdf' | translate"
      container="body"
      placement="left">
      <div class="red fa fa-file-pdf fa-2xl"></div>
    </button>
  </div>
</div>

<div *ngIf="invoice?.parentInvoiceId" class="alert alert-danger">
  <span>{{ 'User.Invoice.ParentInvoiceInfoText' | translate }}</span>
  <a [routerLink]="['/user/invoice', invoice.parentInvoiceId]">
    {{ 'Global.Invoice' | translate }} {{ invoice.parentInvoiceId }}
  </a>
</div>

<div *ngIf="invoice?.status == invoiceStatus.Declined" class="alert alert-danger">
  <span>{{ 'User.Invoice.InvoiceDeclinedInfoText' | translate }}</span>
  <br /><br />
  <span>{{ invoice.declineReason }}</span>
  <br /><br />
  <div *ngIf="invoice?.childInvoiceId; else noChildInvoice">
    <span>{{ 'User.Invoice.InvoiceDeclinedChildInvoiceInfoText' | translate }}</span>
    <a [routerLink]="['/user/invoice', invoice.childInvoiceId]">
      {{ 'Global.Invoice' | translate }} {{ invoice.childInvoiceId }}
    </a>
  </div>
  <ng-template #noChildInvoice>
    <span>{{ 'User.Invoice.InvoiceDeclinedNoChildInvoiceInfoText' | translate }} </span>
    <a href="javascript:void(0);" (click)="cloneInvoice()">{{ 'User.Invoice.CloneInvoice' | translate }}</a>
  </ng-template>
</div>

<div *ngIf="invoice?.status == invoiceStatus.Approved" class="alert alert-danger">
  <span>{{ 'User.Invoice.InvoiceApprovedInfoText' | translate }}</span>
  <br /><br />
  <div *ngIf="invoice?.childInvoiceId; else noChildInvoice">
    <span>{{ 'User.Invoice.InvoiceApprovedChildInvoiceInfoText' | translate }}</span>
    <a [routerLink]="['/user/invoice', invoice.childInvoiceId]">
      {{ 'Global.Invoice' | translate }} {{ invoice.childInvoiceId }}
    </a>
  </div>
  <ng-template #noChildInvoice>
    <span>{{ 'User.Invoice.InvoiceApprovedNoChildInvoiceInfoText' | translate }} </span>
    <a href="javascript:void(0);" (click)="cloneInvoice()">{{ 'User.Invoice.CloneInvoice' | translate }}</a>
  </ng-template>
</div>

<table class="table table-striped table-bordered" *ngIf="invoice && patient">
  <thead class="thin-border-bottom">
    <tr>
      <th>{{ 'Global.CaseNumber' | translate }}</th>
      <th>{{ 'Global.PatientID' | translate }}</th>
      <th>{{ 'Global.LastName' | translate }}</th>
      <th>{{ 'Global.FirstName' | translate }}</th>
      <th>{{ 'Global.DateOfBirth' | translate }}</th>
      <th>{{ 'Global.InvoiceDate' | translate }}</th>
      <th>{{ 'Global.HospitalizationFrom' | translate }}</th>
      <th>{{ 'Global.HospitalizationUntil' | translate }}</th>
      <th class="text-right">{{ 'Global.TotalTaxPoints' | translate }}</th>
      <th>{{ 'Global.SentAt' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr class="evenrow">
      <td>{{ invoice.caseNumber }}</td>
      <td>{{ patient.id }}</td>
      <td>{{ patient.lastName }}</td>
      <td>{{ patient.firstName }}</td>
      <td>{{ patient.dateOfBirth | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.createDate | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.dateFrom | date: 'dd.MM.yyyy' }}</td>
      <td>{{ invoice.dateTo | date: 'dd.MM.yyyy' }}</td>
      <td class="text-right">{{ invoice.total | round: 2 }}</td>
      <td>{{ invoice.lastSentDate | date: 'dd.MM.yyyy' }}</td>
    </tr>
  </tbody>
</table>

<table class="table table-striped table-bordered" *ngIf="invoice && invoice.memo">
  <thead class="thin-border-bottom">
    <tr>
      <th>{{ 'Global.Memo' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr class="evenrow">
      <td>{{ invoice.memo }}</td>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-between">
  <div class="page-header">
    <h1>{{ 'Global.Sessions' | translate }}</h1>
  </div>
  <div class="header-toolbar">
    <button
      class="btn btn-xs btn-info"
      *ngIf="invoice?.status == invoiceStatus.Draft"
      routerLink="/user/invoice/visits/{{ invoice.id }}">
      {{ 'User.Invoice.AddEditVisits' | translate }}
    </button>
    <button class="btn btn-xs btn-success" *ngIf="invoice?.status == invoiceStatus.Draft" (click)="addInvoiceSession()">
      {{ 'User.Invoice.AddInvoiceSession' | translate }}
    </button>
  </div>
</div>

<ng-template #sessionActions let-session="session">
  <button
    class="btn btn-sm mb-1"
    *ngIf="invoice?.status == invoiceStatus.Draft"
    style="padding: 5px; margin: 0px"
    (click)="deleteInvoiceSession(session)"
    [tooltip]="'User.Invoice.DeleteInvoiceSession' | translate">
    <i class="red fa fa-trash fa-lg"></i>
  </button>
  <button
    class="btn btn-sm mb-1"
    *ngIf="invoice?.status == invoiceStatus.Draft && session.type == 0"
    style="padding: 5px; margin: 0px"
    (click)="editInvoiceSurcharges(session)"
    [tooltip]="'User.Invoice.EditInvoiceSurcharges' | translate">
    <i class="green fa fa-plus-minus fa-lg"></i>
  </button>
  <button
    *ngIf="invoice?.status == invoiceStatus.Draft && session.type == 0"
    class="btn btn-sm mb-1"
    style="padding: 5px; margin: 0px"
    (click)="addServiceProvider(session)"
    [tooltip]="'User.Invoice.AddAssistant' | translate">
    <i class="fa fa-user-plus fa-lg"></i>
  </button>
</ng-template>

<ng-template #serviceProviderActions let-serviceProvider="serviceProvider">
  <button
    *ngIf="invoice?.status == invoiceStatus.Draft"
    class="btn btn-sm mb-1"
    style="padding: 5px; margin: 0px"
    (click)="deleteServiceProvider(serviceProvider)"
    [tooltip]="'User.Invoice.DeleteServiceProvider' | translate">
    <i class="red fa fa-trash fa-lg"></i>
  </button>
  <button
    *ngIf="invoice?.status == invoiceStatus.Draft && !serviceProvider.isMainServiceProvider"
    class="btn btn-sm mb-1"
    style="padding: 5px; margin: 0px"
    (click)="editServiceProvider(serviceProvider)"
    [tooltip]="'User.Invoice.EditAssistant' | translate">
    <i class="green fa fa-pencil-alt fa-lg"></i>
  </button>
</ng-template>

<ng-template #positionActions let-session="session">
  <button
    *ngIf="invoice?.status == invoiceStatus.Draft"
    class="btn btn-sm mb-1"
    style="padding: 5px; margin: 0px"
    (click)="editTariffPositions(session)"
    [tooltip]="'User.Invoice.EditPositions' | translate"
    placement="left"
    container="body">
    <i class="green fa fa-pencil-alt fa-lg"></i>
  </button>
</ng-template>

<ng-template #visitActions>
  <button
    *ngIf="invoice?.status == invoiceStatus.Draft"
    class="btn btn-sm mb-1"
    style="padding: 5px; margin: 0px"
    routerLink="/user/invoice/visits/{{ invoice.id }}"
    [tooltip]="'User.Invoice.EditVisits' | translate"
    placement="left"
    container="body">
    <i class="green fa fa-pencil-alt fa-lg"></i>
  </button>
</ng-template>

<ng-template
  #subtotalTooltip
  let-session="session"
  let-position="position"
  let-serviceProvider="serviceProvider"
  let-subtotalWithoutSurcharges="subtotalWithoutSurcharges">
  <ng-container></ng-container>
  {{ 'Global.PositionValue' | translate }} ({{ position.catalogItem.value }}) * {{ 'Global.Valuation' | translate }} ({{
    position.multiplier
  }})
  <ng-container
    *ngIf="
      serviceProvider &&
      !serviceProvider.isMainServiceProvider &&
      position.catalogItem.type == catalogItemType.TariffPosition
    ">
    * {{ 'Global.Factor' | translate }} ({{ serviceProvider.feeKindMultiplier }})
  </ng-container>
  = {{ subtotalWithoutSurcharges }}
  <ng-container *ngFor="let surcharge of position.isMainItem ? session.surcharges : []">
    <br />
    + {{ 'Global.Surcharge' | translate }} ({{ surcharge.catalogItem.surchargeMultiplier }} *
    {{ surcharge.surchargeCount }}) * {{ subtotalWithoutSurcharges }} =
    {{ calculateSurcharge(surcharge, subtotalWithoutSurcharges) }}
  </ng-container>
</ng-template>

<ng-template
  #positionEntry
  let-session="session"
  let-sessionIndex="sessionIndex"
  let-serviceProvider="serviceProvider"
  let-serviceProviderIndex="serviceProviderIndex"
  let-position="position"
  let-positionIndex="positionIndex"
  let-isVisit="session.type == 1">
  <tr [ngClass]="sessionIndex % 2 !== 0 ? 'oddrow' : 'evenrow'">
    <td
      *ngIf="positionIndex == 0 && serviceProviderIndex == 0"
      [attr.rowspan]="calculateRowSpan(session, 0, isVisit)"
      style="width: 120px">
      <ng-container *ngTemplateOutlet="sessionActions; context: { session: session, isVisit }"></ng-container>
    </td>
    <td *ngIf="positionIndex == 0 && serviceProviderIndex == 0" [attr.rowspan]="calculateRowSpan(session, 0, isVisit)">
      {{ session.date | date: 'dd.MM.yyyy' }}
    </td>
    <td *ngIf="isVisit || positionIndex == 0" [attr.rowspan]="calculateRowSpan(session, 1, isVisit)">
      <div *ngIf="serviceProvider">
        <i
          *ngIf="serviceProvider.guestAssistant"
          class="fa-solid fa-person-shelter"
          style="padding-right: 5px"
          [tooltip]="'Global.GuestDoctor' | translate">
        </i>
        <span style="white-space: nowrap">{{ serviceProvider.userFirstName }} {{ serviceProvider.userLastName }}</span>
        <br />
        <ng-container
          *ngTemplateOutlet="serviceProviderActions; context: { serviceProvider: serviceProvider }"></ng-container>
      </div>
      <div *ngIf="isVisit">
        <span style="white-space: nowrap">{{ 'Global.Visit' | translate }}</span>
      </div>
      <div *ngIf="serviceProvider && serviceProvider.hasBeenCutToValue">
        <span
          >{{
            'User.Invoice.SessionServiceProviderHasBeenCut'
              | translate: { hasBeenCutToValue: serviceProvider.hasBeenCutToValue }
          }}
        </span>
      </div>
    </td>
    <td>
      <div *ngIf="position">
        {{ position.catalogItem.column01 }}
      </div>
    </td>
    <td>
      <app-expandable-text
        *ngIf="position; else noPosition"
        [text]="position.catalogItem | catalogItemInvoiceTextTransform"
        [showHtml]="true"
        [maxTextLength]="30"></app-expandable-text>
      <ng-template #noPosition>
        {{
          serviceProvider.isMainServiceProvider
            ? ('User.Invoice.NoPositionForServiceProviderInfoText' | translate)
            : ('User.Invoice.NoPositionForAssistantInfoText' | translate)
        }}
      </ng-template>
    </td>
    <td>
      <div *ngIf="position">
        {{ position.catalogItem.value }}
      </div>
    </td>
    <td>
      <div *ngIf="position">
        {{ position.multiplier }}
      </div>
    </td>
    <td>
      <div
        *ngIf="
          serviceProvider &&
          serviceProvider.feeKindTitle &&
          position &&
          (position.catalogItem.type == catalogItemType.TariffPosition ||
            position.catalogItem.type == catalogItemType.FlatRateForAssistant)
        ">
        <app-expandable-text [text]="serviceProvider.feeKindTitle" [maxTextLength]="30"></app-expandable-text>
      </div>
    </td>
    <td>
      <div>
        {{ getFactorPosition(position, serviceProvider) | nullEmptyString }}
      </div>
    </td>
    <td>
      <ng-container *ngIf="position && position.isMainItem && session.surcharges && session.surcharges.length">
        <ng-container *ngFor="let item of session.surcharges">
          <app-expandable-text
            [text]="item.catalogItem | catalogItemInvoiceTextTransform"
            [showHtml]="true"
            [maxTextLength]="30"></app-expandable-text>
          <br />
        </ng-container>
      </ng-container>
    </td>
    <td>
      <ng-container *ngIf="position && position.isMainItem && session.surcharges && session.surcharges.length">
        <ng-container *ngFor="let item of session.surcharges">
          {{ item.catalogItem.surchargeMultiplier * item.surchargeCount }} ({{ item.catalogItem.surchargeMultiplier }} *
          {{ item.surchargeCount }})
          <br />
        </ng-container>
      </ng-container>
    </td>
    <td class="text-right">
      <ng-template #tooltip>
        <ng-template
          [ngTemplateOutlet]="subtotalTooltip"
          [ngTemplateOutletContext]="{
            session: session,
            serviceProvider: serviceProvider,
            position: position,
            subtotalWithoutSurcharges: calculateSubtotalWithoutSurcharges(serviceProvider, position),
          }">
        </ng-template>
      </ng-template>
      <div *ngIf="position" [tooltip]="tooltip" placement="left" containerClass="tooltip-1000" container="body">
        {{ calculateSubtotal(session, serviceProvider, position) | number }}
      </div>
    </td>
    <td
      *ngIf="isVisit || (positionIndex == 0 && serviceProviderIndex == 0)"
      [attr.rowspan]="calculateRowSpan(session, 2, isVisit)"
      style="width: 50px">
      <ng-container *ngIf="serviceProvider">
        <ng-container *ngTemplateOutlet="positionActions; context: { session: session }"></ng-container>
      </ng-container>
      <ng-container *ngIf="isVisit">
        <ng-container *ngTemplateOutlet="visitActions"></ng-container>
      </ng-container>
    </td>
  </tr>
</ng-template>

<table class="table table-striped table-bordered" style="width: 100%" *ngIf="invoice">
  <thead class="thin-border-bottom">
    <tr>
      <th></th>
      <th style="white-space: nowrap">{{ 'Global.TreatmentDate' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.ServiceProvider' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.Number' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.ServiceText' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.PositionValue' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.Valuation' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.Kind' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.Factor' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.Surcharge' | translate }}</th>
      <th style="white-space: nowrap">{{ 'Global.SurchargeFactor' | translate }}</th>
      <th style="white-space: nowrap" class="text-right">{{ 'Global.SubTotalTaxPoints' | translate }}</th>
      <th style="white-space: nowrap"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let session of invoice.sessions; let sessionIndex = index">
      <ng-container *ngIf="session.type == 0">
        <ng-container
          *ngIf="session.serviceProviders.length == 0 && (session.surcharges.length > 0 || !session.visitPosition)">
          <ng-container
            *ngTemplateOutlet="
              positionEntry;
              context: { session: session, sessionIndex: sessionIndex, serviceProviderIndex: 0, positionIndex: 0 }
            ">
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let serviceProvider of session.serviceProviders; let serviceProviderIndex = index">
          <ng-container *ngIf="getPositionsForServiceProvider(session, serviceProvider) as serviceProviderPositions">
            <ng-container *ngIf="serviceProviderPositions.length == 0">
              <ng-container
                *ngTemplateOutlet="
                  positionEntry;
                  context: {
                    session: session,
                    sessionIndex: sessionIndex,
                    serviceProvider: serviceProvider,
                    serviceProviderIndex: serviceProviderIndex,
                    positionIndex: 0,
                  }
                ">
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let position of serviceProviderPositions; let positionIndex = index">
              <ng-container
                *ngTemplateOutlet="
                  positionEntry;
                  context: {
                    session: session,
                    sessionIndex: sessionIndex,
                    serviceProvider: serviceProvider,
                    serviceProviderIndex: serviceProviderIndex,
                    position: position,
                    positionIndex: positionIndex,
                  }
                ">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="session.type == 1">
        <ng-container
          *ngTemplateOutlet="
            positionEntry;
            context: {
              session: session,
              sessionIndex: sessionIndex,
              serviceProviderIndex: 0,
              position: session.visitPosition,
              positionIndex: 0,
            }
          ">
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>
